import * as React from "react"
import { PageLayout } from "../components/layouts/page_layout"
import { Portfolio } from "../components/home/portfolio"
import { MyProcess } from "../components/home/my_process"
import { Pitch } from "../components/home/pitch"
import { Reachout } from "../components/home/reachout"
import { Brands } from "../components/home/brands"
import { HomeBanner } from "../components/home/banner"
import SEO from "../components/common/seo"
import { AavgeenLogo } from '../assets/icons/index';

// markup
const IndexPage = () => {
  return (
    <PageLayout banner={HomeBanner}>
      <>
        <SEO
          title="Aavgeen Singh"
          description="I'm a Product-focused engineering leader with 8+ years of expertise in crafting seamless web and app experiences, building high-performing teams, leveraging AI to supercharge workflows, and applying Web3 knowledge to drive decentralization where it matters most."
          keywords={[
            'Engineering manager Portfolio',
            'Web3 Engineering manager',
            'Developer portfolio',
            'Web3 Developer portfolio',
            'Web3 Engineering manager portfolio',
            'top engineering managers',
            'EM',
            'EM blog',
            'Product',
            'Digital marketing',
            'UI/UX',
            'branding',
            'mobile apps',
            'flutter',
            'web app',
            'website',
            'reactjs',
            'react',
            'cool portfolios',
            'engineering management services',
            'tailwind css',
            'tailwind',
          ]}
          lang="en"
          imageUrl={AavgeenLogo}
        />
        <Portfolio />
        <Pitch />
        <MyProcess />
        <Reachout />
        <Brands className="flex items-center lg:hidden" />
      </>
    </PageLayout>
  )
}

export default IndexPage
